import { Box, Button } from "@chakra-ui/react"
import { Link } from "react-router-dom";
import { Link as LinkA } from "@chakra-ui/react";
import { FaCaretRight } from "react-icons/fa";
import { treatmentsMenuList } from "../../data/tereatmentMenu"


export const TreatmentsLists = ({ isA = false }) => {

    const RnameBox = ({ children }) => {
        return (
            <Box
                display={['flex']}
                flexDirection={['column']}
                padding={['10px 0']}
            >
                {children}
            </Box>
        )
    }
    const Card = ({ treatment }) => {
        return (
            <Link to={`/treatments/${treatment.link}`}>
                <Button
                    leftIcon={<FaCaretRight />}
                    colorScheme="teal"
                    variant='outline'
                    fontSize={['sm', 'sm', 'md']}
                    whiteSpace={['pre-wrap']}
                    size={['md', 'lg']}
                    height={['50px', '50px']}
                >
                    {treatment.rname ? <RnameBox>{treatment.rname.map((name) => <p key={name}>{name}</p>)} </RnameBox> : treatment.name}
                </Button>
            </Link>
        )
    }
    const CardA = ({ treatment }) => {
        return (
            <LinkA href={`/treatments#${treatment.link}`}>
                <Button
                    leftIcon={<FaCaretRight />}
                    colorScheme="teal"
                    variant='outline'
                    fontSize={['sm', 'sm', 'md']}
                    whiteSpace={['pre-wrap']}
                    size={['md', 'lg']}
                    height={['50px', '50px']}
                >
                    {treatment.rname ?
                        <RnameBox>
                            {treatment.rname.map((name) => <p key={name}>{name}</p>)}
                        </RnameBox>
                        : treatment.name}
                </Button>
            </LinkA>
        )
    }

    return (
        <Box
            display='flex'
            flexWrap={['wrap']}
            margin={['0 auto']}
            w={['80%', '80%', '60%']}
            gap={['0.5em']}
            justifyContent={['start']}>
            {isA ?
                treatmentsMenuList.map((treatment) => {
                    return (
                        <CardA treatment={treatment} key={treatment.name} />
                    )
                }) :
                treatmentsMenuList.map((treatment) => {
                    return (
                        <Card treatment={treatment} key={treatment.name} />
                    )
                })}
        </Box>
    )
}